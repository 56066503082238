import React, { useState, useEffect, useMemo } from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import en_US from '@douyinfe/semi-ui/lib/es/locale/source/en_US';
import ru_RU from '@douyinfe/semi-ui/lib/es/locale/source/ru_RU';
import { LocaleProvider } from '@douyinfe/semi-ui';
import Keycloak from 'keycloak-js';
import { isNull } from 'lodash';

import { history } from './helpers/history';

//import components;
import SideMenu from './components/nav/SideMenu';
import ServicesPage from './components/ServicesPage';
import ProjectsPage from './components/ProjectsPage';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import ProfilePage from './components/ProfilePage';
import OsintPage from './components/Tools/OsintPage';

import './app.css';

export default function App() {

    const [loggedIn, setLoggedIn] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const keycloak = useMemo(() => new Keycloak('/keycloak.json'), []);

    useEffect(() => {
        keycloak.init({ onLoad: 'check-sso' }).then(authenticated => {
          setLoggedIn(authenticated);
          setLoaded(true);       
        });
    }, []);

    const onLoginChange = (bool) => {
        setLoggedIn(bool);
    }

    let authButtonBehaviour = loggedIn ? "Logout" : "LogIn / Register";

    return( 
        <LocaleProvider locale={en_US}>
            <div id="app" className="App">
                {!loaded && 
                    <div id="app_loading">
                        <p>Loading...</p>
                    </div>
                }
                {loaded &&
                    <Router history={history}>

                        <div id="work_area">
                            <SideMenu 
                                loggedIn={loggedIn}
                                behaviour={authButtonBehaviour}
                                keycloak={keycloak}
                                onLoginChange={onLoginChange}
                            />
                            <Routes>
                                <Route path="*" element={<Navigate to="/services" /> }/>
                                <Route exact path="/" element={<Navigate to="/services" /> }/>
                                <Route exact path="/services" element={<ServicesPage/>} />
                                <Route exact path="/projects" element={<ProjectsPage/>} />
                                <Route exact path="/about" element={<AboutPage/>} />
                                <Route exact path="/contacts" element={<ContactPage/>} />
                                {loggedIn && <Route exact path="/profile" element={<ProfilePage/>} />}
                                {loggedIn && <Route exact path="/tools/osint" element={<OsintPage/>} />}
                            </Routes>
                        </div>
                    </Router>
                }
            </div>
        </LocaleProvider>
    )
};