import { IconGlobeStroke, IconLikeHeart, IconFile, IconDisc, IconBox, IconUserGroup, IconUnlock, IconDesktop } from '@douyinfe/semi-icons';
import { TbWorldWww, TbAutomation } from "react-icons/tb";
import { MdOutlineGeneratingTokens } from "react-icons/md";
import { FaMobileAlt, FaLinux, FaLaptop, FaUnity, FaDesktop } from "react-icons/fa";
import { BiJoystick } from "react-icons/bi";
import { LuBook, LuDisc3 } from "react-icons/lu";
import { IoSettingsOutline, IoLogoWindows } from "react-icons/io5";
import { SiUnrealengine } from "react-icons/si";
import { GiBrain } from "react-icons/gi";

export const projects = [
    {   
        id: 1,
        project: {
            name: "International Medical Crypto Platform",
            dev_name: "EMRYS",
            icon: <IconLikeHeart className="sizedIcon"/>,
            types: [
                { 
                    type: "Web",
                    icon: <TbWorldWww/>
                },
                { 
                    type: "Crypto",
                    icon: <MdOutlineGeneratingTokens/>
                },
                { 
                    type: "Mobile",
                    icon: <FaMobileAlt/>
                }
            ],
            stage: "development",
            category: {
                name: "Medicine",
                icon: <IconLikeHeart/>
            },
            in_progress: true,
            release_date: "01/01/2028",
            description: "Emrys is a revolutionary international healthcare platform leveraging blockchain technology and cryptocurrency to provide free, high-quality medical services globally. By connecting patients, doctors, and medical institutions, Emrys ensures secure and transparent exchange of medical information. Utilizing smart contracts, the platform protects data privacy and streamlines service access without financial barriers. Emrys empowers users to receive world-class healthcare by minimizing bureaucratic hurdles and eliminating costs, fostering a more accessible and efficient healthcare ecosystem for everyone involved. Join Emrys in transforming the future of medicine through innovation and inclusivity.",
        },
        socials: {
            x: {
                enable: true,
                link: 'https://x.com/EmrysMedical'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/emrys_medical_news'
                    },
                    {
                        name: 'Bot',
                        link: 'https://t.me/emrys_medical_bot'
                    },
                    {
                        name: 'Group',
                        link: 'https://t.me/emrys_medical_discussion'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 2,
        project: {
            name: "International Psychological Support Platform",
            dev_name: "Asclepius",
            icon: <IconUserGroup className="sizedIcon"/>,
            types: [
                { 
                    type: "Web",
                    icon: <TbWorldWww/>
                },
                { 
                    type: "Crypto",
                    icon: <MdOutlineGeneratingTokens/>
                },
                { 
                    type: "Mobile",
                    icon: <FaMobileAlt/>
                }
            ],
            stage: "development",
            category: {
                name: "Medicine",
                icon: <IconUserGroup />
            },
            in_progress: true,
            release_date: "01/01/2027",
            description: "This platform offers free psychological support to individuals in need of guidance and assistance in the field of mental health. It provides a safe and anonymous environment for users, creating comfortable conditions for communication. The platform connects people with qualified professionals who offer consultations, resources, and tools to improve emotional well-being. Aiming to eliminate barriers to access, it makes mental health support available to everyone, regardless of financial circumstances. The primary focus is on creating a safe space for sharing experiences, receiving support, and finding pathways to healing and personal development.",
        },
        socials: {
            x: {
                enable: true,
                link: 'https://x.com/PsyTherapyMedical'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/psytherapy_medical_news'
                    },
                    {
                        name: 'Bot',
                        link: 'https://t.me/psytherapy_medical_bot'
                    },
                    {
                        name: 'Group',
                        link: 'https://t.me/psytherapy_medical_discussion'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 3,
        project: {
            name: "Automated Operating System Assembly Platform",
            dev_name: "Vulcan",
            icon: <IconDisc className="sizedIcon"/>,
            types: [
                { 
                    type: "Web",
                    icon: <TbWorldWww/>
                },
                { 
                    type: "Mobile",
                    icon: <FaMobileAlt/>
                },
                { 
                    type: "Automation",
                    icon: <TbAutomation/>
                },
                { 
                    type: "Configuration",
                    icon: <IoSettingsOutline/>
                },
                { 
                    type: "Operating systems",
                    icon: <LuDisc3/>
                }
            ],
            stage: "development",
            category: {
                name: "Automation",
                icon: <IconDisc />
            },
            in_progress: true,
            release_date: "01/12/2025",
            description: 'This modern platform is designed for the automated assembly of operating systems, aimed at simplifying and enhancing the customization process for developers and organizations. Users can effortlessly create tailored operating systems by integrating essential programs, applying unique interface styles, modifying the kernel, and implementing advanced encryption features. This powerful tool streamlines the entire assembly process, allowing developers to focus on innovation rather than manual configurations. By harnessing its capabilities, users can ensure that their operating systems are not only functional but also secure and visually appealing.',
        },
        socials: {
            x: {
                enable: true,
                link: '/'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/vulcan_news'
                    },
                    {
                        name: 'Bot',
                        link: 'https://t.me/vulcan_bot'
                    },
                    {
                        name: 'Group',
                        link: 'https://t.me/vulcan_discussion'
                    },
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 4,
        project: {
            name: "Security Breach Tester",
            dev_name: "Artemida",
            icon: <IconUnlock className="sizedIcon"/>,
            types: [
                { 
                    type: "Web",
                    icon: <TbWorldWww/>
                },
                { 
                    type: "Mobile",
                    icon: <FaMobileAlt/>
                },
                { 
                    type: "Linux",
                    icon: <FaLinux/>
                }
            ],
            stage: "development",
            category: {
                name: "Automation",
                icon: <IconUnlock />
            },
            in_progress: true,
            release_date: "01/12/2026",
            description: "This platform provides users with the ability to test the security of their systems and applications. It offers a wide range of tools and resources for identifying vulnerabilities and assessing the level of protection. Users can conduct both automated and manual tests, analyze results, and receive recommendations for improving security. The platform is designed for developers, IT professionals, and companies looking to safeguard their data and infrastructure from potential threats. The primary focus is on ease of use, accessibility, and effectiveness, enabling users to quickly and efficiently identify and mitigate risks, ensuring robust protection of their digital assets.",
        },
        socials: {
            x: {
                enable: true,
                link: '/'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/artemida_news'
                    },
                    {
                        name: 'Bot',
                        link: 'https://t.me/artemida_bot'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 5,
        project: {
            name: "AI OS",
            dev_name: "Samaritan",
            icon: <IconDesktop className="sizedIcon"/>,
            types: [
                { 
                    type: "AI",
                    icon: <GiBrain/>
                },
                { 
                    type: "Mobile",
                    icon: <FaMobileAlt/>
                },
                { 
                    type: "Desktop",
                    icon: <FaDesktop/>
                }
            ],
            stage: "development",
            category: {
                name: "OS",
                icon: <IconDesktop />
            },
            in_progress: true,
            release_date: "01/01/2029",
            description: "An AI-Powered Operating System for Corporate Needs. Advanced artificial intelligence to streamline operations, enhance productivity, and optimize workflows across your organization. Intelligent automation to reduce routine tasks, data-driven insights for informed decision-making, enhanced collaboration tools for seamless teamwork, robust security measures to protect sensitive information, scalability to adapt to your growing business, and a user-friendly interface that promotes easy adoption across teams.",
        },
        socials: {
            x: {
                enable: true,
                link: '/'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/samaritan_news'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 6,
        project: {
            name: "AI Web",
            dev_name: "Samaritan Web",
            icon: <IconGlobeStroke className="sizedIcon"/>,
            types: [
                { 
                    type: "AI",
                    icon: <GiBrain/>
                },
                { 
                    type: "Mobile",
                    icon: <FaMobileAlt/>
                },
                { 
                    type: "Desktop",
                    icon: <FaDesktop/>
                }
            ],
            stage: "development",
            category: {
                name: "Web browser",
                icon: <IconGlobeStroke />
            },
            in_progress: true,
            release_date: "01/01/2029",
            description: "An AI-Powered Web Browser for Corporate Needs. Advanced artificial intelligence designed to streamline operations, boost productivity, and optimize workflows within your organization. Intelligent automation to minimize routine tasks, data-driven insights for informed decision-making, enhanced collaboration tools for seamless teamwork, robust security measures to safeguard sensitive information, scalability to accommodate your growing business, and a user-friendly interface that facilitates easy adoption across teams.",
        },
        socials: {
            x: {
                enable: true,
                link: '/'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/samaritan_news'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 7,
        project: {
            name: "Interactive Infinite Book",
            dev_name: "Diary of a Madman",
            icon: <IconFile className="sizedIcon"/>,
            types: [
                { 
                    type: "Web",
                    icon: <TbWorldWww/>
                },
                { 
                    type: "Mobile",
                    icon: <FaMobileAlt/>
                },
                { 
                    type: "Interactive",
                    icon: <BiJoystick/>
                },
                { 
                    type: "Book",
                    icon: <LuBook/>
                }
            ],
            stage: "development",
            category: {
                name: "Book",
                icon: <IconFile/>
            },
            in_progress: true,
            release_date: "01/06/2026",
            description: 'Enter a world where reality twists and sanity fades in "Diary of a Madman". This gripping horror novel takes you on an unending journey through the mind of a tormented soul. As you turn each page, you will be drawn deeper into a chilling narrative filled with dark secrets, haunting visions, and the ever-present threat of madness. Every entry in this diary reveals a new layer of horror, blurring the lines between the writer’s nightmarish imagination and the terrifying truth. Will you dare to uncover the secrets that lie within? Prepare for a reading experience that never truly ends, as the horrors of the mind linger long after the last page is turned. Dare to delve into the madness?',
        },
        socials: {
            x: {
                enable: false,
                link: null
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/diary_of_madman_news'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 8,
        project: {
            name: "Diary of a Madman (Game)",
            dev_name: "Diary of a Madman: Labyrinth of the Mind",
            icon: <IconBox className="sizedIcon"/>,
            types: [
                { 
                    type: "Desktop",
                    icon: <FaLaptop/>
                },
                { 
                    type: "UE5",
                    icon: <SiUnrealengine/>
                },
                { 
                    type: "Windows",
                    icon: <IoLogoWindows/>
                },
                { 
                    type: "Linux",
                    icon: <FaLinux/>
                }
            ],
            stage: "development",
            category: {
                name: "Game",
                icon: <IconBox />
            },
            in_progress: true,
            release_date: "01/08/2026",
            description: "Step into the fragmented psyche of a haunted protagonist as you navigate the twisting, ever-changing corridors of their own mind—a labyrinth filled with haunting memories, dark secrets, and manifestations of their deepest fears. In this horror game, the environment will dynamically shift around you, reflecting the chaos and turmoil within. Each turn may reveal unsettling visions and grotesque entities that embody the protagonist's inner demons. Solve intricate puzzles that challenge your perception of reality, and confront the shadows lurking in the deepest recesses of your mind.",
        },
        socials: {
            x: {
                enable: true,
                link: '/'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/diary_of_madman_game_news'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 9,
        project: {
            name: "Adventures of little girl",
            dev_name: "Adventures of little girl: The Forgotten Dreams",
            icon: <IconBox className="sizedIcon"/>,
            types: [
                { 
                    type: "Desktop",
                    icon: <FaLaptop/>
                },
                { 
                    type: "Unity",
                    icon: <FaUnity/>
                },
                { 
                    type: "Windows",
                    icon: <IoLogoWindows/>
                },
                { 
                    type: "Linux",
                    icon: <FaLinux/>
                }
            ],
            stage: "development",
            category: {
                name: "Game",
                icon: <IconBox />
            },
            in_progress: true,
            release_date: "01/01/2027",
            description: "This is an indie horror game that invites players to embark on a mesmerizing journey through a dreamlike realm where nightmares and fantasies intertwine. Players control a brave little girl as she traverses a series of vividly contrasting worlds, each filled with its own unique challenges and breathtaking visuals. The game seamlessly blends elements of horror and fantasy, creating an immersive experience that keeps players on their toes. With each world offering its own distinct atmosphere and narrative, players will unravel the mysteries of the girl's dreams, discovering the deeper meanings behind her journey.",
        },
        socials: {
            x: {
                enable: true,
                link: '/'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/aolg_game_news'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 10,
        project: {
            name: "Adventures of little prince",
            dev_name: "Adventures of little prince: Whispers in the Twilight",
            icon: <IconBox className="sizedIcon"/>,
            types: [
                { 
                    type: "Desktop",
                    icon: <FaLaptop/>
                },
                { 
                    type: "Unity",
                    icon: <FaUnity/>
                },
                { 
                    type: "Windows",
                    icon: <IoLogoWindows/>
                },
                { 
                    type: "Linux",
                    icon: <FaLinux/>
                }
            ],
            stage: "development",
            category: {
                name: "Game",
                icon: <IconBox />
            },
            in_progress: true,
            release_date: "01/01/2028",
            description: "This is an indie horror game inspired by the finest works of Edgar Allan Poe, where darkness and mystery intertwine in an engaging and terrifying adventure. Immerse yourself in a world where the brave little prince embarks on a journey through grim and unsettling landscapes filled with ghosts, secrets, and inner demons. Each location—from abandoned castles to shadowy forests—is rich with atmospheric details and surreal elements that reflect the gothic and mystical essence of Poe's writings. Discover dark mysteries and find light even in the darkest corners as the little prince sets out on a quest for his true self in this captivating and haunting adventure inspired by the greatest works of Edgar Allan Poe.",
        },
        socials: {
            x: {
                enable: true,
                link: '/'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/aolp_game_news'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 11,
        project: {
            name: "Adventures of little prince 2",
            dev_name: "Adventures of little prince: Phantoms of the Starry Night",
            icon: <IconBox className="sizedIcon"/>,
            types: [
                { 
                    type: "Desktop",
                    icon: <FaLaptop/>
                },
                { 
                    type: "Unity",
                    icon: <FaUnity/>
                },
                { 
                    type: "Windows",
                    icon: <IoLogoWindows/>
                },
                { 
                    type: "Linux",
                    icon: <FaLinux/>
                }
            ],
            stage: "development",
            category: {
                name: "Game",
                icon: <IconBox />
            },
            in_progress: true,
            release_date: "01/01/2029",
            description: "The second part of the game. This new installment features an entirely new story, introducing fresh enemies and captivating environments. Players will explore diverse worlds filled with stunning celestial landscapes, each brimming with unique challenges and mysteries. ",
        },
        socials: {
            x: {
                enable: true,
                link: '/'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/aolp2_game_news'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 12,
        project: {
            name: "Dungeon Keeper 2: Remastered",
            dev_name: "Dungeon Keeper 2: Remastered",
            icon: <IconBox className="sizedIcon"/>,
            types: [
                { 
                    type: "Desktop",
                    icon: <FaLaptop/>
                },
                { 
                    type: "Unity",
                    icon: <FaUnity/>
                },
                { 
                    type: "Windows",
                    icon: <IoLogoWindows/>
                },
                { 
                    type: "Linux",
                    icon: <FaLinux/>
                }
            ],
            stage: "development",
            category: {
                name: "Game",
                icon: <IconBox />
            },
            in_progress: true,
            release_date: "01/01/2026",
            description: "Step back into the darkly whimsical world of 'Dungeon Keeper 2' with this stunning remaster that revitalizes the beloved classic for a new generation of players. Take on the role of the ultimate dungeon overlord, where you must build and manage your underground lair, recruit a horde of mischievous minions, and defend your domain from pesky heroes seeking to vanquish you. With enhanced graphics, improved gameplay mechanics, and all the original charm intact, players can once again experience the thrill of strategic dungeon management, cunning traps, and epic battles.",
        },
        socials: {
            x: {
                enable: true,
                link: '/'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/dk2_remaster_news'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 13,
        project: {
            name: "Enemy Infestation: Remastered",
            dev_name: "Enemy Infestation: Remastered",
            icon: <IconBox className="sizedIcon"/>,
            types: [
                { 
                    type: "Desktop",
                    icon: <FaLaptop/>
                },
                { 
                    type: "Unity",
                    icon: <FaUnity/>
                },
                { 
                    type: "Windows",
                    icon: <IoLogoWindows/>
                },
                { 
                    type: "Linux",
                    icon: <FaLinux/>
                }
            ],
            stage: "development",
            category: {
                name: "Game",
                icon: <IconBox />
            },
            in_progress: true,
            release_date: "01/01/2026",
            description: "'Enemy Infestation' plunges players into a gripping, fast-paced strategy experience where survival is the ultimate goal. Set in a dystopian future, you must fend off relentless waves of alien invaders intent on taking over your territory. Utilize a variety of weapons, traps, and tactical strategies to outsmart and outmaneuver the enemy forces. As you progress, unlock new technologies and upgrade your defenses to withstand increasingly challenging onslaughts. With its intense gameplay, immersive environments, and heart-pounding action, 'Enemy Infestation' offers a thrilling test of skill and strategy that will keep you on the edge of your seat.",
        },
        socials: {
            x: {
                enable: true,
                link: '/'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/ei_remaster_news'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
    {   
        id: 14,
        project: {
            name: "Dark Colony: Remastered",
            dev_name: "Dark Colony: Remastered",
            icon: <IconBox className="sizedIcon"/>,
            types: [
                { 
                    type: "Desktop",
                    icon: <FaLaptop/>
                },
                { 
                    type: "Unity",
                    icon: <FaUnity/>
                },
                { 
                    type: "Windows",
                    icon: <IoLogoWindows/>
                },
                { 
                    type: "Linux",
                    icon: <FaLinux/>
                }
            ],
            stage: "development",
            category: {
                name: "Game",
                icon: <IconBox />
            },
            in_progress: true,
            release_date: "01/01/2026",
            description: "'Dark Colony' invites players to explore a hauntingly atmospheric sci-fi world where humanity's survival hangs in the balance. Set on a distant planet, you must build and manage a colony while navigating the dangers of alien life forms and environmental challenges. Gather resources, establish colonies, and develop advanced technologies to ensure your survival against hostile creatures and rival factions. With a rich narrative and immersive gameplay, 'Dark Colony' challenges players to make strategic decisions that will shape the fate of their colony. Will you thrive in the shadows of this alien world, or will you succumb to the darkness? The choice is yours in this gripping blend of strategy and survival.",
        },
        socials: {
            x: {
                enable: true,
                link: '/'
            },
            telegram: {
                enable: true,
                links: [
                    {
                        name: 'News',
                        link: 'https://t.me/dc_remaster_news'
                    }
                ]
            }
        },
        buttons: {
            kickstarter: {
                enable: true,
                link: '/'
            },
            donate: {
                enable: true,
                link: '/'
            },
            website: {
                enable: true,
                link: '/'
            }
        }
    },
]