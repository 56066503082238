import React from 'react';
import { Col, Row, Typography, Button, Input, Select, CheckboxGroup } from '@douyinfe/semi-ui';
import { IconHelpCircle, IconStar, IconRefresh, IconMail, IconEdit, IconKey, IconUser, IconShield, IconSearch, IconLink, IconSetting, IconIndenpentCornersStroked, IconPhone, IconGlobe, IconUserGroup, IconMapPin, IconClock, IconLanguage, IconCalendar } from '@douyinfe/semi-icons';
const { Text } = Typography;

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: 'John',
        surname: 'Doe',
        apiToken: 'xyz123-abc456-def789',
        login: 'johndoe@example.com',
        status: 'Verified',
        telegram: '@johndoe123',
        phone: '+1 (555) 123-4567',
        website: 'https://johndoe.com',
        company: 'New Company',
        location: 'New York, USA',
        timezone: 'UTC-5',
        language: 'en',
        accountCreated: 'March 17, 2025',
        lastLogin: 'March 18, 2025, 14:30 UTC',
        currentSubscriptionPlan: 'none',
        subscriptionPlan: 'Pro [300$]',
        rateLimits: '500 requests/day, 300 used'
      },
      isEditing: false
    };
  }

  handleEditToggle = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  handleInputChange = (field, value) => {
    this.setState({
      user: { ...this.state.user, [field]: value }
    });
  };

  getStatusStyle = (status) => {
    switch (status) {
      case 'Verified':
        return { color: '#4CAF50' }; 
      case 'Banned':
        return { color: '#F44336' };
      case 'Created':
        return { color: '#FFFFFF' };
      case 'Not Verified':
        return { color: '#FFC107' };
      default:
        return { color: '#b0c0c0' };
    }
  };

  regenerateToken = () => {
    const newToken = 'add-generation-func';
    this.setState({
      user: { ...this.state.user, apiToken: newToken }
    });
  };

  getSubscriptionPrice = (plan) => {
    const match = plan.match(/\[(\d+\$)\]/);
    return match ? match[1] : '0$';
  };

  render() {
    const { user, isEditing } = this.state;

    const permissions = [
      { value: 'AI', label: 'AI', icon: <IconUser />, disabled: true },
      { value: 'OSINT', label: 'OSINT', icon: <IconSearch />, disabled: true },
      { value: 'SecurityTools', label: 'Security Tools', icon: <IconShield />, disabled: true }, 
      { value: 'AutomationTools', label: 'Automation Tools', icon: <IconIndenpentCornersStroked />, disabled: true }, 
      { value: 'VPNBuilder', label: 'VPN Builder', icon: <IconLink />, disabled: true },
      { value: 'Integrations', label: 'Integrations', icon: <IconSetting />, disabled: true }
    ];

    const languageOptions = [
      { value: 'en', label: 'English', prefix: '🇬🇧' },
      { value: 'ru', label: 'Русский', prefix: '🇷🇺' },
      { value: 'de', label: 'Deutsch', prefix: '🇩🇪' }
    ];

    const timezoneOptions = [
      { value: 'UTC-12', label: 'UTC-12:00' },
      { value: 'UTC-11', label: 'UTC-11:00' },
      { value: 'UTC-10', label: 'UTC-10:00' },
      { value: 'UTC-9', label: 'UTC-09:00' },
      { value: 'UTC-8', label: 'UTC-08:00' },
      { value: 'UTC-7', label: 'UTC-07:00' },
      { value: 'UTC-6', label: 'UTC-06:00' },
      { value: 'UTC-5', label: 'UTC-05:00' },
      { value: 'UTC-4', label: 'UTC-04:00' },
      { value: 'UTC-3', label: 'UTC-03:00' },
      { value: 'UTC-2', label: 'UTC-02:00' },
      { value: 'UTC-1', label: 'UTC-01:00' },
      { value: 'UTC+0', label: 'UTC+00:00' },
      { value: 'UTC+1', label: 'UTC+01:00' },
      { value: 'UTC+2', label: 'UTC+02:00' },
      { value: 'UTC+3', label: 'UTC+03:00' },
      { value: 'UTC+4', label: 'UTC+04:00' },
      { value: 'UTC+5', label: 'UTC+05:00' },
      { value: 'UTC+6', label: 'UTC+06:00' },
      { value: 'UTC+7', label: 'UTC+07:00' },
      { value: 'UTC+8', label: 'UTC+08:00' },
      { value: 'UTC+9', label: 'UTC+09:00' },
      { value: 'UTC+10', label: 'UTC+10:00' },
      { value: 'UTC+11', label: 'UTC+11:00' },
      { value: 'UTC+12', label: 'UTC+12:00' },
      { value: 'UTC+13', label: 'UTC+13:00' },
      { value: 'UTC+14', label: 'UTC+14:00' }
    ];

    const subscriptionOptions = [
      { value: 'Basic [20$]', label: 'Basic [20$]' },
      { value: 'Advanced [100$]', label: 'Advanced [100$]' },
      { value: 'Pro [300$]', label: 'Pro [300$]' },
      { value: 'Maximum [500$]', label: 'Maximum [500$]' },
      ...permissions.map(p => ({
        value: `Only ${p.label} [50$]`,
        label: `Only ${p.label} [50$]`
      }))
    ];

    return (
      <div className="grid-container">
        <div className="page-header">
          <Text className="page-title">Profile</Text>
        </div>
        <div className="card-row">
          <div className="card-col" style={{ maxWidth: '650px', margin: '20px' }}>
            <div className="card-content-fixed">
              <div className="info-card">
                <div className="card-header">
                  <span className="card-title-xl">User Information</span>
                  <Button
                    icon={<IconEdit />}
                    className="contact-button"
                    onClick={this.handleEditToggle}
                  >
                    {isEditing ? 'Save' : 'Edit'}
                  </Button>
                </div>
                <div className="card-body">
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Text className="card-paragraph"><strong>Name</strong></Text>
                          <Input
                            prefix={<IconUser />}
                            value={user.name}
                            onChange={(v) => this.handleInputChange('name', v)}
                            className="contact-input"
                            disabled={!isEditing}
                          />
                        </Col>
                        <Col span={12}>
                          <Text className="card-paragraph"><strong>Surname</strong></Text>
                          <Input
                            prefix={<IconUser />}
                            value={user.surname}
                            onChange={(v) => this.handleInputChange('surname', v)}
                            className="contact-input"
                            disabled={!isEditing}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Text className="card-paragraph"><strong>Login</strong></Text>
                          <Input
                            prefix={<IconMail />}
                            value={user.login}
                            onChange={(v) => this.handleInputChange('login', v)}
                            className="contact-input"
                            disabled={!isEditing}
                          />
                        </Col>
                        <Col span={12}>
                          <Text className="card-paragraph"><strong>Phone Number</strong></Text>
                          <Input
                            prefix={<IconPhone />}
                            value={user.phone}
                            onChange={(v) => this.handleInputChange('phone', v)}
                            className="contact-input"
                            disabled={!isEditing}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Text className="card-paragraph"><strong>Telegram</strong></Text>
                          <Input
                            prefix={<IconLink />}
                            value={user.telegram}
                            onChange={(v) => this.handleInputChange('telegram', v)}
                            className="contact-input"
                            disabled={!isEditing}
                          />
                        </Col>
                        <Col span={12}>
                          <Text className="card-paragraph"><strong>Website</strong></Text>
                          <Input
                            prefix={<IconGlobe />}
                            value={user.website}
                            onChange={(v) => this.handleInputChange('website', v)}
                            className="contact-input"
                            disabled={!isEditing}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Text className="card-paragraph"><strong>Company</strong></Text>
                      <Input
                        prefix={<IconUserGroup />}
                        value={user.company}
                        onChange={(v) => this.handleInputChange('company', v)}
                        className="contact-input"
                        disabled={!isEditing}
                      />
                    </Col>
                    <Col span={24}>
                      <Text className="card-paragraph"><strong>Location</strong></Text>
                      <Input
                        prefix={<IconMapPin />}
                        value={user.location}
                        onChange={(v) => this.handleInputChange('location', v)}
                        className="contact-input"
                        disabled={!isEditing}
                      />
                    </Col>
                    <Col span={24}>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Text className="card-paragraph"><strong>Timezone</strong></Text>
                          <Select
                            prefix={<IconClock />}
                            value={user.timezone}
                            onChange={(v) => this.handleInputChange('timezone', v)}
                            className="contact-select"
                            optionList={timezoneOptions}
                            disabled={!isEditing}
                          />
                        </Col>
                        <Col span={12}>
                          <Text className="card-paragraph"><strong>Preferred Language</strong></Text>
                          <Select
                            prefix={<IconLanguage />}
                            value={user.language}
                            onChange={(v) => this.handleInputChange('language', v)}
                            className="contact-select"
                            optionList={languageOptions.map(option => ({
                              value: option.value,
                              label: (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                  {option.prefix} {option.label}
                                </div>
                              )
                            }))}
                            disabled={!isEditing}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Text className="card-paragraph"><strong>Account Created: </strong><b style={{ color: '#b0c0c0' }}>{user.accountCreated}</b></Text>
                    </Col>
                    <Col span={24}>
                      <Text className="card-paragraph"><strong>Status: </strong><b style={this.getStatusStyle(user.status)}>{user.status}</b></Text>
                    </Col>
                    <Col span={24}>
                      <Text className="card-paragraph"><strong>Last Login: </strong><b style={{ color: '#b0c0c0' }}>{user.lastLogin}</b></Text>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <div className="card-col" style={{ maxWidth: '650px', margin: '20px' }}>
            <div className="card-content-fixed">
              <div className="info-card">
                <div className="card-header">
                  <span className="card-title-xl">Access</span>
                </div>
                <div className="card-body">
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Text className="section-title">
                        Security
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Button
                        icon={<IconHelpCircle />}
                        className="contact-button"
                        style={{ width: '100%' }}
                      >
                        Set Security Question
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button
                        icon={<IconKey />}
                        className="contact-button"
                        style={{ width: '100%' }}
                      >
                        Change Password
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Text className="section-title">
                        API
                      </Text>
                      <Text className="card-paragraph"><strong>Token: </strong><b style={{ color: '#b0c0c0' }}>{user.apiToken}</b></Text>
                    </Col>
                    <Col span={24}>
                      <Button
                        icon={<IconRefresh />}
                        className="contact-button"
                        style={{ width: '100%' }}
                        onClick={this.regenerateToken}
                      >
                        Regenerate Token
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Text className="card-paragraph"><strong>Rate Limits: </strong><b style={{ color: '#b0c0c0' }}>{user.rateLimits}</b></Text>
                    </Col>
                    <Col span={24}>
                      <Text className="section-title">
                        Subscription
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text className="card-paragraph"><strong>Current Subscription Plan: </strong><b style={{ color: '#b0c0c0' }}>{user.currentSubscriptionPlan}</b></Text>
                    </Col>
                    <Col span={24}>
                      <Text className="card-paragraph"><strong>Permissions</strong></Text>
                      <CheckboxGroup
                        className="ui-checkbox-group"
                        options={permissions.map(p => ({
                          label: (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: '#b0c0c0' }}>
                              {p.icon} {p.label}
                            </div>
                          ),
                          value: p.value,
                          disabled: p.disabled
                        }))}
                        value={permissions.map(p => p.value)}
                        direction="vertical"
                      />
                    </Col>
                    <Col span={24}>
                      <Text className="card-paragraph"><strong>Subscription Plan</strong></Text>
                      <Select
                        prefix={<IconStar />}
                        value={user.subscriptionPlan}
                        onChange={(v) => this.handleInputChange('subscriptionPlan', v)}
                        className="contact-select"
                        optionList={subscriptionOptions}
                        style={{ width: '100%' }}
                      />
                    </Col>
                    <Col span={24}>
                      <Button
                        className="contact-button"
                        style={{ width: '100%' }}
                      >
                        Subscribe {this.getSubscriptionPrice(user.subscriptionPlan)}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePage;