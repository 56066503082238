import React from 'react';
import { Col, Row, Card, Typography, Button, ButtonGroup, Tag, Space, Popover } from '@douyinfe/semi-ui';
import { projects } from './data/projects';
import { isEmpty } from 'lodash';
import { FaKickstarter, FaExternalLinkAlt, FaDonate, FaTelegram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

class ProjectsPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="grid-container">
                <div className="page-header">
                    <Typography.Text className="page-title">Projects</Typography.Text>
                </div>

                <Row 
                    type="flex" 
                    justify="center" 
                    gutter={[32, 32]} 
                    className="card-row"
                >
                    { !isEmpty(projects) ?
                        projects.map((item) => {
                            return (
                                <Col 
                                    xs={24} 
                                    sm={12} 
                                    md={12}
                                    lg={12} 
                                    key={item.id}
                                    className="card-col"
                                >
                                    <div className="card-content">
                                        <Card 
                                            title={
                                                <Typography.Text className="card-title-l">
                                                    {item.project.icon}
                                                    {item.project.name}
                                                </Typography.Text>
                                            }
                                            className="info-card"
                                            headerExtraContent={
                                                <ButtonGroup>
                                                    {item.buttons.kickstarter.enable && 
                                                        <Popover 
                                                            showArrow 
                                                            content={<span className="popover-content">Kickstarter</span>} 
                                                            position="top"
                                                            className="popover-custom"
                                                        >
                                                            <Button 
                                                                icon={<FaKickstarter size={18}/>} 
                                                                theme="borderless" 
                                                                className="button-custom"
                                                            />
                                                        </Popover>
                                                    }
                                                    {item.buttons.donate.enable && 
                                                        <Popover 
                                                            showArrow 
                                                            content={<span className="popover-content">Donate</span>} 
                                                            position="top"
                                                            className="popover-custom"
                                                        >
                                                            <Button 
                                                                icon={<FaDonate size={18}/>} 
                                                                theme="borderless" 
                                                                className="button-custom"
                                                            />
                                                        </Popover>
                                                    }
                                                    {item.buttons.website.enable && 
                                                        <Popover 
                                                            showArrow 
                                                            content={<span className="popover-content">Open link</span>} 
                                                            position="top"
                                                            className="popover-custom"
                                                        >
                                                            <Button 
                                                                icon={<FaExternalLinkAlt size={18}/>} 
                                                                theme="borderless" 
                                                                className="button-custom"
                                                            />
                                                        </Popover>
                                                    }
                                                </ButtonGroup>
                                            }
                                        >
                                            <div className="card-content-inner-projects">
                                                <Typography.Paragraph className="card-paragraph">
                                                    <strong>Code name:</strong> {item.project.dev_name}
                                                </Typography.Paragraph>
                                                <Typography.Paragraph className="card-paragraph">
                                                    <strong>Category:</strong> 
                                                    <Tag 
                                                        size="large" 
                                                        color='teal' 
                                                        type="solid" 
                                                        shape='circle' 
                                                        prefixIcon={item.project.category.icon}
                                                        className="tag-custom-no-border tag-teal"
                                                    >
                                                        {item.project.category.name}
                                                    </Tag>
                                                </Typography.Paragraph>
                                                <Typography.Paragraph className="card-paragraph">
                                                    <strong>Type:</strong>
                                                    <Space wrap className="tag-custom-no-border">
                                                        {item.project.types.map((el) => (
                                                            <Tag 
                                                                key={el.type}
                                                                size="large" 
                                                                color='cyan' 
                                                                type="solid" 
                                                                shape='circle' 
                                                                prefixIcon={el.icon}
                                                                className="tag-custom-no-border tag-cyan"
                                                            >
                                                                {el.type}
                                                            </Tag>
                                                        ))}
                                                    </Space>
                                                </Typography.Paragraph>
                                                <Typography.Paragraph className="card-paragraph">
                                                    <strong>Stage:</strong>
                                                    <Tag 
                                                        size="large" 
                                                        color='amber' 
                                                        type="solid" 
                                                        shape='circle'
                                                        className="tag-custom-no-border tag-amber"
                                                    >
                                                        {item.project.stage}
                                                    </Tag>
                                                </Typography.Paragraph>
                                                <Typography.Paragraph className="card-paragraph">
                                                    <strong>Release date:</strong>
                                                    <Tag 
                                                        size="large" 
                                                        color='blue' 
                                                        type="solid" 
                                                        shape='circle'
                                                        className="tag-custom-no-border tag-blue"
                                                    >
                                                        {item.project.release_date}
                                                    </Tag>
                                                </Typography.Paragraph>
                                                <Typography.Paragraph className="card-description">
                                                    <strong><b>Description:</b></strong> {item.project.description}
                                                </Typography.Paragraph>
                                                <Typography.Paragraph className="card-resources">
                                                    <strong>Resources:</strong>
                                                    <ButtonGroup className="button-group-custom">
                                                        {item.socials.x.enable && 
                                                            <Popover 
                                                                showArrow 
                                                                content={<span className="popover-content">News on X</span>} 
                                                                position="top"
                                                                className="popover-custom"
                                                            >
                                                                <Button 
                                                                    icon={<FaSquareXTwitter size={18}/>} 
                                                                    theme="borderless" 
                                                                    className="button-custom"
                                                                />
                                                            </Popover>
                                                        }
                                                        {item.socials.telegram.enable && 
                                                            item.socials.telegram.links.map((btn) => (
                                                                <Popover 
                                                                    key={btn.name}
                                                                    showArrow 
                                                                    content={<span className="popover-content">{btn.name}</span>}
                                                                    position="top"
                                                                    className="popover-custom"
                                                                >
                                                                    <Button 
                                                                        icon={<FaTelegram size={18}/>} 
                                                                        theme="borderless" 
                                                                        className="button-custom"
                                                                    />
                                                                </Popover>
                                                            ))
                                                        }
                                                    </ButtonGroup>
                                                </Typography.Paragraph>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                            )
                        }):
                        <div className="no-data">
                            No data available
                        </div>
                    }
                </Row>
            </div>
        );
    }
}

export default ProjectsPage;