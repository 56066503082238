import React from 'react';
import { Typography } from '@douyinfe/semi-ui';

class AboutPage extends React.Component {
    render() {
        return (
            <div className="grid-container">
                <div className="page-header">
                    <Typography.Text className="page-title">About Us</Typography.Text>
                </div>
                <div className="about-content">
                    <Typography.Paragraph>
                        We’re a small, dedicated company passionate about helping our clients succeed. With a focus on flexibility, we offer a variety of services designed to meet different needs, from creative solutions to practical support. Our goal is simple: to provide reliable help wherever it’s needed.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        Our team brings together a range of skills, allowing us to take on diverse projects. Whether it’s building a website, offering technical advice, or creating marketing materials, we approach every task with care and attention to detail. We may not be the biggest, but we make up for it with our commitment and hands-on approach.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        Over time, we’ve worked on a number of projects that we’re proud of. From helping local businesses get online to supporting individuals with custom solutions, each job has taught us something new. We enjoy tackling challenges and finding ways to make things work, no matter the size of the task.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        What drives us is a love for what we do and a desire to keep improving. As a small company, we stay close to our clients, listening to their needs and adapting as we go. We’re always exploring new ideas to expand what we can offer, while keeping things straightforward and personal.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        If you’re looking for a team that’s easy to work with and ready to assist, we’d love to hear from you. We’re here to lend a hand with whatever you’ve got in mind, bringing our skills and enthusiasm to every project.
                    </Typography.Paragraph>
                </div>
            </div>
        );
    }
}

export default AboutPage;