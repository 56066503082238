import React from 'react';
import { Col, Row, Card, Typography, Button, ButtonGroup, Tag, Space, Popover } from '@douyinfe/semi-ui';
import { services } from './data/services';
import { isEmpty } from 'lodash';
import { IconCart, IconInfoCircle, IconAlertTriangle } from '@douyinfe/semi-icons';

class ServicesPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="grid-container">
                <div className="page-header">
                    <Typography.Text className="page-title">Services</Typography.Text>
                </div>

                <Row 
                    type="flex" 
                    justify="center" 
                    gutter={[32, 32]} 
                    className="card-row"
                >
                    { !isEmpty(services) ?
                        services.map((item) => {
                            return (
                                <Col 
                                    xs={24} 
                                    sm={12} 
                                    md={12} 
                                    lg={12} 
                                    key={item.id}
                                    className="card-col"
                                >
                                    <div className="card-content">
                                        <Card 
                                            title={
                                                <Typography.Text className="card-title-xl">
                                                    {item.icon}
                                                    {item.name}
                                                </Typography.Text>
                                            }
                                            className="info-card"
                                            headerExtraContent={
                                                <ButtonGroup>
                                                    {item.buttons.warning && 
                                                        <Popover 
                                                            showArrow 
                                                            content={<span className="popover-content">Attention</span>} 
                                                            position="top"
                                                            className="popover-custom"
                                                        >
                                                            <Button 
                                                                icon={<IconAlertTriangle size={18}/>} 
                                                                theme="borderless" 
                                                                className="button-custom"
                                                            />
                                                        </Popover>
                                                    }
                                                    {item.buttons.info && 
                                                        <Popover 
                                                            showArrow 
                                                            content={<span className="popover-content">Info</span>} 
                                                            position="top"
                                                            className="popover-custom"
                                                        >
                                                            <Button 
                                                                icon={<IconInfoCircle size={18}/>} 
                                                                theme="borderless" 
                                                                className="button-custom"
                                                            />
                                                        </Popover>
                                                    }
                                                    {item.buttons.order && 
                                                        <Popover 
                                                            showArrow 
                                                            content={<span className="popover-content">Order</span>} 
                                                            position="top"
                                                            className="popover-custom"
                                                        >
                                                            <Button 
                                                                icon={<IconCart size={18}/>} 
                                                                theme="borderless" 
                                                                className="button-custom"
                                                            />
                                                        </Popover>
                                                    }
                                                </ButtonGroup>
                                            }
                                        >
                                            <div className="card-content-inner-services">
                                                <Typography.Paragraph className="card-paragraph">
                                                    <strong>Price:</strong> from 
                                                    <Tag size="small" color="green" className="tag-custom tag-green">
                                                        {item.price.min}
                                                    </Tag> 
                                                    to 
                                                    <Tag size="small" color="red" className="tag-custom tag-red">
                                                        {item.price.max}
                                                    </Tag>
                                                </Typography.Paragraph>
                                                <Typography.Paragraph className="card-paragraph">
                                                    <strong>Range:</strong> from 
                                                    <Tag size="small" color="green" className="tag-custom tag-green">
                                                        {item.range.min}
                                                    </Tag> 
                                                    to 
                                                    <Tag size="small" color="red" className="tag-custom tag-red">
                                                        {item.range.max}
                                                    </Tag>
                                                </Typography.Paragraph>
                                                <Typography.Paragraph className="card-description">
                                                    <strong><b>Description:</b></strong> {item.description}
                                                </Typography.Paragraph>
                                                {item.stack.enable && 
                                                    <Typography.Paragraph className="card-resources">
                                                        <strong>{item.stack.name}:</strong> 
                                                        <Space wrap>
                                                            {item.stack.elements.map((el) => (
                                                                <Tag 
                                                                    key={el.text}
                                                                    size="large" 
                                                                    color="white" 
                                                                    type="ghost" 
                                                                    shape="circle" 
                                                                    className="tag-custom tag-white"
                                                                >
                                                                    {el.text}
                                                                </Tag>
                                                            ))}
                                                        </Space>
                                                    </Typography.Paragraph>
                                                }
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                            )
                        }):
                        <div className="no-data">
                            No data available
                        </div>
                    }
                </Row>
            </div>
        );
    }
}

export default ServicesPage;