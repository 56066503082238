import React from 'react';
import { Col, Row, Card, Typography, Button, Input, TextArea, Select } from '@douyinfe/semi-ui';
import { IconMail, IconFacebook } from '@douyinfe/semi-icons';
import { FaTelegram } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: 'General Inquiry',
            message: ''
        };
    }

    handleInputChange = (field, value) => {
        this.setState({ [field]: value });
    };

    handleSubmit = () => {
        console.log('Form submitted:', this.state);
        this.setState({ name: '', email: '', subject: 'General Inquiry', message: '' });
    };

    render() {
        return (
            <div className="grid-container">
                <div className="page-header" style={{marginBottom: '30px'}}>
                    <Typography.Text className="page-title">Contact Us</Typography.Text>
                    <Typography.Text className="contact-page-text">
                        For any questions, feel free to reach out to us via the form or the contacts below.
                    </Typography.Text>
                </div>

                <Row type="flex" justify="center" gutter={[32, 32]} className="contact-card-row">
                    <Col xs={24} sm={10} md={8} lg={7} className="contact-card-col">
                        <div className="contact-text-section">
                            <Typography.Text className="contact-text-title">Contacts</Typography.Text>
                            <Typography.Paragraph className="contact-card-paragraph">
                                <IconMail />
                                <strong>Email:</strong> exception003@protonmail.com
                            </Typography.Paragraph>
                            <Typography.Paragraph className="contact-card-paragraph">
                                <FaTelegram />
                                <strong>Telegram:</strong> <a href="https://t.me/exception003" target="_blank" rel="noopener noreferrer">@exception003</a>
                            </Typography.Paragraph>
                            <Typography.Paragraph className="contact-card-paragraph">
                                <IconFacebook />
                                <strong>Facebook:</strong> <a href="https://www.facebook.com/profile.php?id=100074730362100" target="_blank" rel="noopener noreferrer">Exception003</a>
                            </Typography.Paragraph>
                            <Typography.Paragraph className="contact-card-paragraph">
                                <FaSquareXTwitter />
                                <strong>X:</strong> <a href="https://x.com/Exception003" target="_blank" rel="noopener noreferrer">@Exception003</a>
                            </Typography.Paragraph>
                        </div>
                    </Col>

                    <Col xs={24} sm={14} md={16} lg={17} className="contact-card-col">
                        <div className="contact-card-content">
                            <Card
                                title={<Typography.Text className="card-title-xl">Get in Touch</Typography.Text>}
                                className="contact-card"
                            >
                                <div className="card-body">
                                    <form className="contact-form">
                                        <Input
                                            placeholder="Your Name"
                                            value={this.state.name}
                                            onChange={(value) => this.handleInputChange('name', value)}
                                            className="contact-input"
                                        />
                                        <Input
                                            placeholder="Your Email"
                                            value={this.state.email}
                                            onChange={(value) => this.handleInputChange('email', value)}
                                            className="contact-input"
                                        />
                                        <Select
                                            value={this.state.subject}
                                            onChange={(value) => this.handleInputChange('subject', value)}
                                            className="contact-select"
                                            dropdownStyle={{
                                                background: '#2a3232',
                                                color: '#d0dede',
                                                maxHeight: '150px',
                                                overflowY: 'hidden',
                                                scrollbarWidth: 'none',
                                                '-ms-overflow-style': 'none',
                                            }}
                                        >
                                            <Select.Option value="General Inquiry">General Inquiry</Select.Option>
                                            <Select.Option value="Support">Support</Select.Option>
                                            <Select.Option value="Feedback">Feedback</Select.Option>
                                            <Select.Option value="Partnership">Partnership</Select.Option>
                                        </Select>
                                        <TextArea
                                            placeholder="Your Message"
                                            value={this.state.message}
                                            onChange={(value) => this.handleInputChange('message', value)}
                                            className="contact-textarea"
                                        />
                                        <Button
                                            onClick={this.handleSubmit}
                                            className="contact-button"
                                        >
                                            Send Message
                                        </Button>
                                    </form>
                                </div>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ContactPage;