import React from 'react';
import { Typography, Chat } from '@douyinfe/semi-ui';

const { Text } = Typography;

class OsintPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chats: [
        {
          id: '1',
          role: 'assistant',
          content: 'Welcome to OSINT module! I will help you find any information you need.',
          createAt: new Date('March 18, 2025, 15:00 UTC').getTime()
        }
      ],
      hints: [
        "What search options can you do?",
        "What information can I get from a search?",
        "What countries are available for search?",
      ],      
      isLoading: false
    };
  }

  handleMessageSend = (value) => {
    const { chats, isLoading } = this.state;
    if (!value.trim() || isLoading) return;

    const userMessage = {
      id: `${chats.length + 1}`,
      role: 'user',
      content: value,
      createAt: Date.now()
    };

    this.setState({
      chats: [...chats, userMessage],
      isLoading: true
    });

    setTimeout(() => {
      const responseMessage = {
        id: `${chats.length + 2}`,
        role: 'assistant',
        content: `
                  Available information:
                  1. [ZAGS](https://test.com/first)
                  2. [Basic Info](https://test.com/second)
                  3. [Advanced Info](https://test.com/third)
                  `,
        createAt: Date.now()
      };
      this.setState({
        chats: [...chats, userMessage, responseMessage],
        isLoading: false
      });
    }, 1000);
  };

  render() {
    const { chats, hints } = this.state;

    const roleConfig = {
      user: { name: 'User', avatar: 'https://lf3-static.bytednsdoc.com/obj/eden-cn/ptlz_zlp/ljhwZthlaukjlkulzlp/docs-icon.png' },
      assistant: { name: 'OSINT Bot', avatar: 'https://lf3-static.bytednsdoc.com/obj/eden-cn/ptlz_zlp/ljhwZthlaukjlkulzlp/other/logo.png' }
    };

    return (
      <div className="grid-container">
        <div className="page-header">
          <Text className="page-title">OSINT</Text>
        </div>
        <div className="card-row full">
          <div className="card-col" style={{ flex: 1, width: '100%', margin: '20px' }}>
            <div className="card-content-fixed">
              <div className="info-card-chat">
                <div className="card-body">
                  <Chat
                    chats={chats}
                    hints={hints}
                    roleConfig={roleConfig}
                    mode="bubble"
                    align="leftRight"
                    enableUpload={false}
                    onMessageSend={this.handleMessageSend}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OsintPage;